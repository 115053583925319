export enum RoutePath {
  Offer = '/offer',
  YourGoal = '/your-goal',
  YourName = '/your-name',
  YourContact = '/your-contact',
  YourBirthDate = '/birthday',
  YourTotalIncome = '/total-income',
  YourAddress = '/address',
  YourCreditReport = '/credit-report',
  OutOfState = '/out-of-state',

  CreatingOffer = '/creating-offer',
  HardOffer = '/hard-offer',
  PayOffPlan = '/pay-off-plan',
  PlanSent = '/plan-sent',

  ConfirmLoan = '/confirm-loan',
  VerifyDetailsIntro = '/verify-details-intro',
  YourEmployer = '/your-employer',
  FileUpload = '/file-upload',
  DocuSignSignature = '/docu-sign-signature',
  Plaid = '/employment-verify',
  PlaidCheckingAccounts = '/checking-accounts',
  Citizenship = '/citizenship',
  Alloy = '/alloy',
  RepaymentMethod = '/repayment-method',
  SetupDeduction = '/setup-deduction',
  SetupViaPayroll = '/setup-via-payroll',
  SetupViaHr = '/setup-via-hr',
  VerifyPayrollDeduction = '/verify-payroll-deduction',

  PreQualificationDecline = '/pre-qualification-decline',

  EmailNotificationAlert = '/email-alert',

  BankAccount = '/bank-account',
  NextSteps = '/next-steps',

  OfferStatus = '/offer-status',

  Feedback = '/feedback',
  FundsSent = '/funds-sent',

  Error = '/error',

  // Financial Checkup
  YourPhoneNumber = '/your-phone-number',
  YourEmail = '/your-email',
  YourSavings = '/your-savings',
  YourFinances = '/your-finances',
  YourSSN = '/your-ssn',
  MethodAuth = '/auth',
  Analyzing = '/analyzing',
  CardApplied = '/card-applied',
  HowItWorks = '/how-it-works',

  // Card
  CardNextSteps = '/card-next-steps',
  XSellLoader = '/xsell-loader',

  // Not being used
  Location = '/location',
  LoanAmountRange = '/loan-amount',
  CreditScoreRange = '/credit-score',

  // Open DDA

  OpenDDADone = '/open-dda-done',

  // Missed Payments
  MissedPayment = '/missed-payment',
  MakeAPayment = '/make-a-payment',
  MissedPaymentReason = '/missed-payment-reason',
  Authorization = '/authorization',
  WaitingAuthorization = '/waiting-authorization',
  MakingPayment = '/making-payment',
  PaymentInitiated = '/payment-initiated',
  PaymentFailed = '/payment-failed',
}
