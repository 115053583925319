import { NavigateOptions } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from 'hooks/useDispatchWithUnwrap';

import { getApplicationData } from 'selectors/getApplicationData';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';

import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { CurrentFlow } from 'enums/CurrentFlow';
import { RoutePath } from 'enums/Routes';

import { elementTokenThunk, updateApplicationStatus } from 'thunks';

import { RootState } from 'handlers';
import { setManualFlow } from 'handlers/manualFlow';

import { FormNavigationProps } from 'components/FormNavigation/FormNavigation';

import PreQualificationDecline from 'components/PreQualificationDecline';
import YourAddress from 'components/LoanForm/YourAddress';
import CreditReport from 'components/LoanForm/CreditReport';
import ConfirmLoan from 'components/ConfirmLoan';
import OfferStatus from 'components/OfferStatus';
import Alloy from 'components/Alloy';
import EmailNotificationAlert from 'components/EmailNotificationAlert';
import DocuSign from 'components/DocuSign';
import FundsSent from 'components/FundsSent';
import BankAccount from 'components/BankAccount';
import FileUpload from 'components/FileUpload';
import NextSteps from 'components/NextSteps';
import CardNextSteps from 'components/Card/NextSteps';
import Plaid from 'components/Plaid';
import OutOfState from 'components/OutOfState';
import Offer from 'components/Offer';
import CreatingOffer from 'components/HardOffer/components/CreatingOffer';
import HardOfferSummary from 'components/HardOffer';
import PayOffPlan from 'components/HardOffer/components/PayOffPlan';
import CheckingAccounts from 'components/CheckingAccounts/CheckingAccounts';
import YourName from 'components/LoanForm/YourName/YourName';
import Feedback from 'components/Feedback';
import YourGoal from 'components/LoanForm/YourGoal';
import RepaymentMethod from 'components/RepaymentMethod';
import Citizenship from 'components/Citizenship';
import YourEmployer from 'components/PreApprovedStep/YourEmployer';
import VerifyDetailsIntro from 'components/PreApprovedStep/VerifyDetailsIntro';
import YourTotalIncome from 'components/LoanForm/YourTotalIncome';
import BirthDate from 'components/LoanForm/BirthDate';
import YourContact from 'components/LoanForm/YourContact/YourContact';
import SetupDeduction from 'components/SetupDeduction';
import SetupViaHr from 'components/SetupDeduction/ManualDeduction/components/SetupViaHr';
import VerifyPayrollDeduction from 'components/SetupDeduction/ManualDeduction/components/VerifyPayrollDeduction';
import SetupViaPayroll from 'components/SetupDeduction/ManualDeduction/components/SetupViaPayroll';
import OpenDDADone from 'components/OpenDDADone';
import Error from 'components/Error';
import HowItWorks from 'components/FinancialCheckup/HowItWorks';
import YourFinances from 'components/FinancialCheckup/YourFinances/YourFinances';
import YourSavings from 'components/FinancialCheckup/YourSavings/YourSavings';
import YourEmail from 'components/FinancialCheckup/Email/YourEmail';
import YourPhoneNumber from 'components/FinancialCheckup/PhoneNumber/YourPhoneNumber';
import MethodAuth from 'components/FinancialCheckup/MethodAuth/MethodAuth';
import CardApplied from 'components/FinancialCheckup/CardApplied';
import Analyzing from 'components/FinancialCheckup/Analyzing/Analyzing';
import Applying from 'components/CardFlow/Applying/Applying';
import PlanSent from 'components/HardOffer/components/PlanSent';
import { ManualDeductionMethod } from 'components/SetupDeduction/ManualDeduction/ManualDeduction';
import MissedPaymentReason from 'components/MissedPayments/MissedPaymentReason/MissedPaymentReason';
import MakingPayment from 'components/MissedPayments/MakingPayment/MakingPayment';
import MissedPayment from 'components/MissedPayments/MissedPayment/MissedPayment';
import Authorization from 'components/MissedPayments/Authorization/Authorization';
import WaitingAuthorization from 'components/MissedPayments/WaitingAuthorization/WaitingAuthorization';
import PaymentFailed from 'components/MissedPayments/PaymentFailed/PaymentFailed';
import PaymentInitiated from 'components/MissedPayments/PaymentInitiated/PaymentInitiated';
import MakeAPayment from 'components/MissedPayments/MakeAPayment/MakeAPayment';
import CustomizeCard from 'components/CardFlow/Customize/Customize';
import Loader from 'components/Card/Loader';
import { ErrorType } from 'components/Error/ErrorType';
import { FLOW_TO_STEP_MAP } from 'handlers/applicationStep';

export interface FlowComponentType {
  flags: any;
  navigationInfo: FormNavigationProps;
  handleNext: (result?: boolean) => void;
}

export interface CustomRouterType {
  navigationInfo?: FormNavigationProps;
  component: (arg0: FlowComponentType) => JSX.Element;
  handleNext?: ({
    state,
    navigate,
    flags,
    dispatch,
    dispatchWithUnwrap,
  }: {
    state: RootState;
    navigate: (to: RoutePath, options?: NavigateOptions) => void;
    flags: any;
    dispatch: Dispatch;
    dispatchWithUnwrap: <Returned, ThunkArg, ThunkApiConfig extends AsyncThunkConfig>(
      action: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>,
    ) => Promise<Returned>;
  }) => (success?: boolean) => void;
}

type RouterType = {
  [key in RoutePath]?: CustomRouterType;
};

type FlowRouterType = {
  [flow in CurrentFlow]: RouterType;
};

const CommonRoutesForCardAndCheckup: RouterType = {
  [RoutePath.YourPhoneNumber]: {
    navigationInfo: { showBackLink: true, title: 'Your Phone Number', step: 2, stepCount: 4 },
    component: YourPhoneNumber,
    handleNext: ({ state, dispatchWithUnwrap, navigate }) => async () => {
      navigate(RoutePath.YourEmail);

      const { phone_number: phoneNumber } = getYourContactData(state);
      const { first_name: firstName, last_name: lastName } = getYourNameData(state);

      // fetch method token
      await dispatchWithUnwrap(
        elementTokenThunk({
          phoneNumber,
          firstName,
          lastName,
        }),
      );
    },
  },
  [RoutePath.YourEmail]: {
    navigationInfo: { showBackLink: true, title: 'Your Email', step: 3, stepCount: 4 },
    component: YourEmail,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.MethodAuth);
    },
  },
  [RoutePath.MethodAuth]: {
    navigationInfo: { showBackLink: true, title: 'Connect', step: 4, stepCount: 4 },
    component: MethodAuth,
    handleNext: () => () => {},
  },
};

const CommonRoutesForLoanApply: RouterType = {
  [RoutePath.PayOffPlan]: {
    component: PayOffPlan,
    handleNext: ({ navigate }) => (sendEmail) => {
      if (sendEmail) {
        navigate(RoutePath.PlanSent);
      } else {
        navigate(RoutePath.ConfirmLoan);
      }
    },
  },
  [RoutePath.PlanSent]: {
    component: PlanSent,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.ConfirmLoan);
    },
  },
  [RoutePath.ConfirmLoan]: {
    navigationInfo: { showBackLink: true, title: 'Your Offer' },
    component: ConfirmLoan,
    handleNext: ({ state, navigate, dispatchWithUnwrap }) => async () => {
      const { application } = getApplicationData(state!);
      await dispatchWithUnwrap(
        updateApplicationStatus({
          applicationId: application!.id,
          applicationStatus: ApplicationStatusName.Verification,
        }),
      );
      navigate(RoutePath.VerifyDetailsIntro);
    },
  },
  [RoutePath.VerifyDetailsIntro]: {
    navigationInfo: { showBackLink: true, title: 'Verify' },
    component: VerifyDetailsIntro,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourEmployer);
    },
  },
  [RoutePath.YourEmployer]: {
    navigationInfo: { showBackLink: true, title: 'Employment', step: 1, stepCount: 5 },
    component: YourEmployer,
    handleNext: ({ navigate }) => (success) => {
      if (success) {
        navigate(RoutePath.FileUpload);
      } else {
        navigate(RoutePath.OfferStatus);
      }
    },
  },
  [RoutePath.FileUpload]: {
    navigationInfo: { showBackLink: true, title: 'Paystubs', step: 2, stepCount: 5 },
    component: FileUpload,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Plaid);
    },
  },
  [RoutePath.Plaid]: {
    navigationInfo: { showBackLink: true, title: 'Bank Account', step: 3, stepCount: 5 },
    component: Plaid,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.PlaidCheckingAccounts);
    },
  },
  [RoutePath.BankAccount]: {
    navigationInfo: { showBackLink: true, title: 'Bank Account', step: 3, stepCount: 5 },
    component: BankAccount,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Citizenship);
    },
  },
  [RoutePath.PlaidCheckingAccounts]: {
    navigationInfo: { showBackLink: true, title: 'Bank Account', step: 3, stepCount: 5 },
    component: CheckingAccounts,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Citizenship);
    },
  },
  [RoutePath.Citizenship]: {
    navigationInfo: { showBackLink: true, title: 'Citizenship', step: 4, stepCount: 5 },
    component: Citizenship,
    handleNext: ({ state, dispatchWithUnwrap, navigate, flags }) => async () => {
      const { application } = getApplicationData(state!);
      if (flags.enableTreasuryPrimeIntegration) {
        navigate(RoutePath.Alloy);
      } else {
        await dispatchWithUnwrap(
          updateApplicationStatus({
            applicationId: application!.id,
            applicationStatus: ApplicationStatusName.ManualVerify,
          }),
        );
        navigate(RoutePath.OfferStatus);
      }
    },
  },
  [RoutePath.Alloy]: {
    navigationInfo: { showBackLink: true, title: 'Upload', step: 5, stepCount: 5 },
    component: Alloy,
    handleNext: ({ navigate }) => async () => {
      navigate(RoutePath.OfferStatus);
    },
  },
  [RoutePath.RepaymentMethod]: {
    navigationInfo: { showBackLink: false, title: 'Repayment Method', step: 1, stepCount: 2 },
    component: RepaymentMethod,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.SetupDeduction);
    },
  },
  [RoutePath.SetupDeduction]: {
    navigationInfo: { showBackLink: true, title: 'Setup Deduction', step: 2, stepCount: 2 },
    component: SetupDeduction,
    handleNext: ({ state, dispatchWithUnwrap, navigate }) => async () => {
      const { application } = getApplicationData(state!);
      await dispatchWithUnwrap(
        updateApplicationStatus({
          applicationId: application!.id,
          applicationStatus: ApplicationStatusName.FinalReview,
        }),
      );
      navigate(RoutePath.OfferStatus);
    },
  },
  [RoutePath.SetupViaPayroll]: {
    navigationInfo: { showBackLink: true, title: 'Setup Deduction' },
    component: SetupViaPayroll,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.VerifyPayrollDeduction, { state: { flow: ManualDeductionMethod.ViaPayroll } });
    },
  },
  [RoutePath.SetupViaHr]: {
    component: SetupViaHr,
  },
  [RoutePath.VerifyPayrollDeduction]: {
    navigationInfo: { showBackLink: true, title: 'Setup Deduction' },
    component: VerifyPayrollDeduction,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.OfferStatus);
    },
  },
  [RoutePath.DocuSignSignature]: {
    component: DocuSign,
  },
  [RoutePath.PreQualificationDecline]: {
    // Can we remove this?
    component: PreQualificationDecline,
  },
  [RoutePath.EmailNotificationAlert]: {
    // Can we remove this?
    component: EmailNotificationAlert,
  },
  [RoutePath.NextSteps]: {
    component: NextSteps,
  },
  [RoutePath.OfferStatus]: {
    component: OfferStatus,
  },
  [RoutePath.Feedback]: {
    component: Feedback,
  },
  [RoutePath.FundsSent]: {
    component: FundsSent,
  },
  [RoutePath.Error]: {
    component: Error,
  },
};

const FlowRouter: FlowRouterType = {
  [CurrentFlow.V1]: {
    [RoutePath.Offer]: {
      navigationInfo: { showBackLink: false, title: 'Healthcare Worker Loan' },
      component: Offer,
      handleNext: ({ navigate, flags, dispatch }) => () => {
        dispatch(setManualFlow(true));
        if (flags.showLoanGoalScreen) {
          navigate(RoutePath.YourGoal);
        } else {
          navigate(RoutePath.YourName);
        }
      },
    },
    [RoutePath.YourGoal]: {
      navigationInfo: { showBackLink: true, title: 'Your Goal' },
      component: YourGoal,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourName);
      },
    },
    [RoutePath.YourName]: {
      navigationInfo: { showBackLink: true, title: 'Apply', step: 1, stepCount: 6 },
      component: YourName,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourAddress);
      },
    },
    [RoutePath.YourAddress]: {
      navigationInfo: { showBackLink: true, title: 'Apply', step: 2, stepCount: 6 },
      component: YourAddress,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourBirthDate);
      },
    },
    [RoutePath.YourBirthDate]: {
      navigationInfo: { showBackLink: true, title: 'Apply', step: 3, stepCount: 6 },
      component: BirthDate,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourTotalIncome);
      },
    },
    [RoutePath.YourTotalIncome]: {
      navigationInfo: { showBackLink: true, title: 'Apply', step: 4, stepCount: 6 },
      component: YourTotalIncome,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourContact);
      },
    },
    [RoutePath.YourContact]: {
      navigationInfo: { showBackLink: true, title: 'Apply', step: 5, stepCount: 6 },
      component: YourContact,
      handleNext: ({ navigate }) => (success) => {
        if (success) {
          navigate(RoutePath.YourCreditReport);
        } else {
          navigate(RoutePath.OutOfState);
        }
      },
    },
    [RoutePath.YourCreditReport]: {
      navigationInfo: { showBackLink: true, title: 'Apply', step: 6, stepCount: 6 },
      component: CreditReport,
      handleNext: () => () => {
        // TODO - change logic in credit report
      },
    },
    [RoutePath.CreatingOffer]: {
      component: CreatingOffer,
      handleNext: ({ navigate }) => (success) => {
        if (success) {
          navigate(RoutePath.HardOffer);
        } else {
          navigate(RoutePath.OfferStatus);
        }
      },
    },
    [RoutePath.HardOffer]: {
      navigationInfo: { showBackLink: false, title: 'Your Loan' },
      component: HardOfferSummary,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.PayOffPlan);
      },
    },
    [RoutePath.OutOfState]: {
      component: OutOfState,
    },
    ...CommonRoutesForLoanApply,
  },
  [CurrentFlow.OpenDDA]: {
    [RoutePath.Plaid]: {
      navigationInfo: { showBackLink: false, title: 'Bank Account' },
      component: Plaid,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.PlaidCheckingAccounts);
      },
    },
    [RoutePath.BankAccount]: {
      navigationInfo: { showBackLink: false, title: 'Bank Account' },
      component: BankAccount,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.Citizenship);
      },
    },
    [RoutePath.PlaidCheckingAccounts]: {
      navigationInfo: { showBackLink: false, title: 'Bank Account' },
      component: CheckingAccounts,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.Citizenship);
      },
    },
    [RoutePath.Citizenship]: {
      navigationInfo: { showBackLink: false, title: 'Citizenship' },
      component: Citizenship,
      handleNext: ({ navigate }) => async () => {
        navigate(RoutePath.Alloy);
      },
    },
    [RoutePath.Alloy]: {
      navigationInfo: { showBackLink: false, title: 'Upload' },
      component: Alloy,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.RepaymentMethod);
      },
    },
    [RoutePath.RepaymentMethod]: {
      navigationInfo: { showBackLink: false, title: 'Repayment Method' },
      component: RepaymentMethod,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.SetupDeduction);
      },
    },
    [RoutePath.SetupDeduction]: {
      component: SetupDeduction,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.DocuSignSignature);
      },
    },
    [RoutePath.SetupViaPayroll]: {
      component: SetupViaPayroll,
    },
    [RoutePath.SetupViaHr]: {
      component: SetupViaHr,
    },
    [RoutePath.VerifyPayrollDeduction]: {
      component: VerifyPayrollDeduction,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.DocuSignSignature);
      },
    },
    [RoutePath.DocuSignSignature]: {
      component: DocuSign,
    },
    [RoutePath.OpenDDADone]: {
      component: OpenDDADone,
    },
    [RoutePath.Error]: {
      component: Error,
    },
  },
  [CurrentFlow.FinancialCheckup]: {
    [RoutePath.YourName]: {
      navigationInfo: { showBackLink: false, title: 'Create Account', step: 1, stepCount: 4 },
      component: YourName,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourPhoneNumber);
      },
    },
    ...CommonRoutesForCardAndCheckup,
    [RoutePath.Analyzing]: {
      component: Analyzing,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourFinances);
      },
    },
    [RoutePath.YourFinances]: {
      navigationInfo: { showBackLink: false, title: 'Financial Checkup' },
      component: YourFinances,
      handleNext: ({ state, navigate }) => () => {
        const { application } = getApplicationData(state!);
        const pathInfo = FLOW_TO_STEP_MAP[state.applicationStep.currentFlow || CurrentFlow.V1](application);
        navigate(pathInfo.checkupNext || pathInfo.path);
      },
    },
    [RoutePath.YourSavings]: {
      navigationInfo: { showBackLink: true, title: 'Debt Consolidation' },
      component: YourSavings,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.HowItWorks);
      },
    },
    [RoutePath.HowItWorks]: {
      navigationInfo: { showBackLink: true, title: 'Debt Consolidation' },
      component: HowItWorks,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.PayOffPlan);
      },
    },
    [RoutePath.CardApplied]: {
      navigationInfo: { showBackLink: true, title: 'Plannery Card' },
      component: CardApplied,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourFinances);
      },
    },
    [RoutePath.CardNextSteps]: {
      // This route exists for when its replaced when Card Flow gets
      // redirected to Financial Checkup Flow
      component: CardNextSteps,
      handleNext: ({ navigate }) => (shouldOfferPlanneryLoan) => {
        if (shouldOfferPlanneryLoan) {
          navigate(RoutePath.YourSavings);
        } else {
          navigate(RoutePath.YourFinances);
        }
      },
    },
    ...CommonRoutesForLoanApply,
  },
  [CurrentFlow.Card]: {
    [RoutePath.YourName]: {
      navigationInfo: { showBackLink: false, title: 'Your Card', step: 1, stepCount: 4 },
      component: CustomizeCard,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourPhoneNumber);
      },
    },
    ...CommonRoutesForCardAndCheckup,
    [RoutePath.Analyzing]: {
      component: Applying,
      handleNext: ({ navigate }) => (success) => {
        if (success) {
          navigate(RoutePath.CardApplied);
        } else {
          navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
        }
      },
    },
    [RoutePath.CardApplied]: {
      navigationInfo: { showBackLink: false, title: 'Plannery Card' },
      component: CardApplied,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.CardNextSteps);
      },
    },
    [RoutePath.CardNextSteps]: {
      component: CardNextSteps,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.XSellLoader);
      },
    },
    [RoutePath.XSellLoader]: {
      component: Loader,
      handleNext: ({ navigate }) => (debtConsolidationPossible) => {
        if (debtConsolidationPossible) {
          navigate(RoutePath.YourSavings);
        } else {
          navigate(RoutePath.YourFinances);
        }
      },
    },
    [RoutePath.YourSavings]: {
      // This route will be replaced when Card Flow gets
      // redirected to Financial Checkup Flow
      component: Loader,
    },
    [RoutePath.YourFinances]: {
      // This route will be replaced when Card Flow gets
      // redirected to Financial Checkup Flow
      component: Loader,
    },
    [RoutePath.Error]: {
      component: Error,
    },
  },
  [CurrentFlow.MissedPayment]: {
    [RoutePath.MissedPayment]: {
      navigationInfo: { showBackLink: false, title: 'Missed Payment' },
      component: MissedPayment,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.MissedPaymentReason);
      },
    },
    [RoutePath.MissedPaymentReason]: {
      navigationInfo: { showBackLink: true, title: 'Reason For Missed Payment' },
      component: MissedPaymentReason,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.MakeAPayment);
      },
    },
    [RoutePath.MakeAPayment]: {
      navigationInfo: { showBackLink: true, title: 'Make a Payment' },
      component: MakeAPayment,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.Plaid);
      },
    },
    [RoutePath.Plaid]: {
      navigationInfo: { showBackLink: true, title: 'Bank Account' },
      component: Plaid,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.PlaidCheckingAccounts);
      },
    },
    [RoutePath.BankAccount]: {
      navigationInfo: { showBackLink: true, title: 'Bank Account' },
      component: BankAccount,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.Authorization);
      },
    },
    [RoutePath.PlaidCheckingAccounts]: {
      navigationInfo: { showBackLink: true, title: 'Bank Account' },
      component: CheckingAccounts,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.Authorization);
      },
    },
    [RoutePath.Authorization]: {
      navigationInfo: { showBackLink: false },
      component: Authorization,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.MakingPayment);
      },
    },
    [RoutePath.WaitingAuthorization]: {
      navigationInfo: { showBackLink: false },
      component: WaitingAuthorization,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.MakingPayment);
      },
    },
    [RoutePath.MakingPayment]: {
      navigationInfo: { showBackLink: false },
      component: MakingPayment,
      handleNext: ({ navigate }) => (success) => {
        if (success) {
          navigate(RoutePath.PaymentInitiated);
        } else {
          navigate(RoutePath.PaymentFailed);
        }
      },
    },
    [RoutePath.PaymentInitiated]: {
      navigationInfo: { showBackLink: false, title: 'Payment Initiated' },
      component: PaymentInitiated,
      handleNext: () => () => {
        window.open('https://www.planneryapp.com/');
      },
    },
    [RoutePath.PaymentFailed]: {
      navigationInfo: { showBackLink: true, title: 'Payment Error' },
      component: PaymentFailed,
    },
  },
};

export default FlowRouter;
